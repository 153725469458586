import { LitElement, html, css, customElement, property } from "lit-element";
import * as icons from '../lib/icons.js';

@customElement('x-icon')
export class Icon extends LitElement {
    @property({ type: String, reflect: true }) icon;

    createRenderRoot() {
        return this;
    }

    firstUpdated() {
        if (!this.getAttribute('aria-label')) {
            this.setAttribute('aria-hidden', 'true');
        }
    }

    render() {
        if (this.icon) {
            return icons[this.icon];
        }
    }
}