// Components
import './components/x-icon';

// Api
import "./api/auth";

// 3rd Party
import '@wcd/deadwisdom.league-dialog';

// Load Session Async
import('./session').then(() => console.debug("+ session components loaded"));

// Rollup will process these, but not us
if (window['debug'] === false) {
    //import('./reset.css');
    //import('./base.css');
    //import('./components.css');
}
